.Join {
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .Join .MainContainer {
    display: flex;
    align-items:center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
  }
  
  .Join .MainContainer .FormContainer .SubmitContainer {
    /* Needed for ... reasons */
    height: 47px;
  }

  .Join .MainContainer .FormContainer .SubmitContainer img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  
:root {
  --rounded: 5px;
  --text-color: #222222;
  --mark-color: 215, 166, 249;
}

body {
  margin: 0;
  padding: 0;
  font-family: "PT Sans", Helvetica, sans-serif;
  color: var(--text-color);
  font-size: 20px;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body,
#root {
  height: 100%;
}

h1 {
  transform: rotate(-1deg);
  color: var(--text-color);
  background-color: rgba(var(--mark-color), 1);
  padding: var(--rounded) calc(var(--rounded) * 2);
  border-radius: var(--rounded);
  font-weight: 700;
  text-align: center;
  width: fit-content;
}

h1 span,
h2 span {
  display: block;
  transform: rotate(1deg);
}

h2 {
  transform: rotate(-1deg);
  color: var(--text-color);
  background-color: rgba(var(--mark-color), 1);
  padding: var(--rounded) calc(var(--rounded) * 2);
  border-radius: var(--rounded);
  font-weight: 700;
  width: fit-content;
}

button,
input[type="submit"] {
  border-radius: var(--rounded);
  font-weight: 700;
  padding: 0.5em;
  border: 2px solid var(--text-color);
  font-size: 20px;
  background-color: white;
  cursor: pointer;
}

button:hover,
input[type="submit"]:hover {
  background-color: #f5f5f5;
}

input {
  display: block;
  border: 0px;
  border-bottom: 2px solid var(--text-color);
  font-size: 20px;
  margin-bottom: 20px;
  height: 47px; /* same as button */
  width: 100%;
}

a {
  text-decoration-thickness: 2px;
  text-decoration-color: rgba(var(--mark-color), 1);
  color: var(--text-color);
  text-underline-offset: 4px;
}

a:hover {
  text-decoration-thickness: 4px;
}

footer {
  color: #cccccc;
  text-align: center;
  width: 100%;
}

.Home {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Home .MainContainer {
  display: flex;
  align-items:center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}

.Home .MainContainer .FormContainer p {
    /* background-color:cadetblue; */
    margin: 40px 0 40px 0;
    line-height: 35px;
    text-align: center;
}

.Home .MainContainer .FormContainer .SubmitContainer {
  /* Needed for ... reasons */
  height: 47px;
}

.Home .MainContainer .FormContainer .SubmitContainer img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.Home .MainContainer .ImageContainer {
  width:30%;
}


@media only screen and (max-width: 768px) {
  .Home .MainContainer .ImageContainer {
    display: none;
  } 
}

.UserCard {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  margin: 0px;
}

.UserCard > :last-child {
  margin-top: 10px;
  text-align: center;
  font-weight: 700;
  width: 100px;
  /* background-color: darkcyan; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Flipping card */

/* https://codepen.io/xtrp/pen/XWWwGRy */

.UserCard .CardContainer {
  perspective: 75rem;
  position: relative;
  background-color: white;
}

.UserCard .CardContainer, .Front, .Back {
  width: calc(50px * 0.8);
  height: calc(80px * 0.8);
  user-select: none;
}

.UserCard .CardContainer .Front, .Back {
  /* background-color: darkcyan; */
  border-radius: calc(var(--rounded) * 0.8);
  border: 1px solid var(--text-color);

  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  overflow: hidden;
  transition: transform 0.8s ease;
}

.UserCard .CardContainer .Back {
  transform: rotateY(180deg);

  display: flex;
  justify-content: center;
  align-items: center;
}

.UserCard .CardContainer .Front {
  border-style: dashed;
} 
.LoadingContainer {
  display: flex;
  align-items: center;
  height: 100%;
}

.LoadingContainer img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.Game {
  padding: 0px 20px 20px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Game .Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Game .Header > :first-child {
  flex: 1;
}

.Game .Header > :last-child {
  text-align: right;
  flex: 1;
}

.Game main {
  flex: 1;
  /* background-color: chocolate; */

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Game main .Table {
  flex: 1;

  display: flex;
  justify-content: center;
  align-items: center;
}

.Game main .Table .Container {
  /* background-color: red; */
  width: fit-content;

  display: flex;
}

.Game main .Table .Container .LeftSeats, .RightSeats {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    min-width: 140px;
  }

  .Game main .Table .Container .LeftSeats {
    align-items: flex-end;
  }

.Game main .Table .Container .SideSeatsContainer {
    display:flex;
    flex-direction: column; 
    align-items: center;
    justify-items: center;
}

.Game main .Table .Container .SideSeatsContainer .BottomSeats, .TopSeats {
    display:flex;
    justify-content: space-around;

    min-height: 140px;
}

.Game main .Table .Container .SideSeatsContainer .ActualTable {
  background-color: #f0f0f0;
  border-radius: var(--rounded);

  padding: 0 10px 0 10px;

  height: 25vh;
  width: 25vw; 
  /* flex: 1; */

  display: flex;
  flex-direction: column;
}

.Game main .Table .Container .SideSeatsContainer .ActualTable .ButtonContainer {
  /* background-color: green; */
  padding: 20px 0 20px 0;
  text-align: center;
}

.Game main .Table .Container .SideSeatsContainer .ActualTable .Info {
  /* background-color: blue; */
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.Game main .Table .Container .SideSeatsContainer .ActualTable .Info p {
  text-align: center;
}

.Game main .Table .Container .SideSeatsContainer .ActualTable .Info .Average {
  /* background-color: blue;  */
  font-size: 40px;
}

.Game main .Stack {
  margin: 20px 0 20px 0;
  display: flex;
  justify-content: center;
}

.Game main .Stack .Container {
  /* background-color: cornflowerblue; */
  text-align: center;
}

.Game main .Stack .Container .Card {
  width: 50px;
  height: 80px;
  margin-right: 1vw;
  margin-top: 5px;
  margin-bottom: 5px;
  /* border-color: rgba(var(--mark-color), 1); */

  transition: transform 0.1s;
}

.Game main .Stack .Container .Card:hover {
  background-color: rgba(var(--mark-color), 0.1);
  transform: translate(0, -2px);
}

.Game main .Stack .Container .Card.Selected {
  background-color: rgba(var(--mark-color), 1);
  transform: translate(0, -10px);
}

.Game main .Stack .Container .Card:last-of-type {
  margin-right: 0;
}

@media only screen and (max-width: 768px) {
  .Game main .Table .Container .LeftSeats {
    display:none;
  }

  .Game main .Table .Container .RightSeats {
    display:none;
  }

  .Game main .Table .Container .SideSeatsContainer  .BottomSeats {
    display:none;
  }

  .Game main .Table .Container .SideSeatsContainer  .TopSeats {
    display:none;
  }

  .Game main .Table .Container .SideSeatsContainer .ActualTable .Info .Average {
    margin: 0px;
  }

  .Game main .Table .Container .SideSeatsContainer .ActualTable .Info .UserCard {
    display:none;
  }
}

